import { Component, AfterViewInit, OnInit, Renderer2, Inject, NgZone, OnDestroy } from '@angular/core';
import { EntriesService } from '../services/entries.service';
import { PersonsService } from '../services/persons.service';
import { errorNotification, successNotification } from '../services/notifications.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { BetterpayService } from '../services/betterpay.service';
import { Firestore, WriteBatch, writeBatch } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { EmailsService } from '../services/emails.service';
import { firstValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';
import { Club } from '../enums/clubs';
import { DOCUMENT } from '@angular/common';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { ShowsService } from '../services/shows.service';
import LogRocket from 'logrocket';
declare var $: any;

// Payment response interfaces
interface ApiResponse {
  response: string;
  responsetext: string;
  authcode: string;
  transactionid: string;
  avsresponse: string;
  cvvresponse: string;
  orderid: string;
  type: string;
  response_code: string;
  first_name: string;
  last_name: string;
  cc_number: string;
  customer_vault_id: string;
  cardholder_auth: string;
  cc_type: string;
  cc_exp: string;
  customer_vault: string;
  entry_mode: string;
  initial_transaction_id: string;
  initiated_by: string;
  network_token_used: string;
  billing_method: string;
}

interface TransactionCommission {
  entry: string;
  id_concurso: string;
  referencia: string;
  monto_pagar: number;
  procesado: boolean;
  created: string;
  tipo: string;
  id_concepto: string;
  quantity: string;
  fecha_pago: string;
  id_responsable: number | null;
  id_entrenador: number | null;
  id_caballo: number | null;
  nombre: string | null;
  pagar: number | null;
  cantidad: number | null;
  deduccion: number | null;
  iva: number | null;
  id_binomio: number | null;
  anulado: boolean | null;
  id: number;
}

interface PaymentData {
  first_name: string;
  last_name: string;
  zip: string;
  reference: string;
  amount: number;
  type: string;
  api_token: string;
  entry: string;
  id_concurso: string;
  id_responsable: number;
  customer_vault_id: string;
  customer_vault_billing_id: string;
  online: boolean;
  payment_token?: string;
}

interface PaymentResponse {
  message: string;
  data: PaymentData;
  apiResponse: ApiResponse;
  transaction: any | null;
  transactionCommission: TransactionCommission;
  paymentId?: string;
  transactionId?: string;
  status?: string;
  last4?: string;
}

// Extend Window interface to include CollectJS
declare global {
  interface Window {
    CollectJS?: any;
  }
}

@Component({
  selector: 'app-betterpay-checkout',
  templateUrl: './betterpay-checkout.component.html',
  styleUrls: ['./betterpay-checkout.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('500ms ease-in', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate('300ms ease-out', style({ opacity: 0 }))
      ])
    ]),
    trigger('stepAnimation', [
      state('inactive', style({
        transform: 'scale(1)'
      })),
      state('active', style({
        transform: 'scale(1.1)',
        backgroundColor: '#0d6efd'
      })),
      transition('inactive => active', [
        animate('300ms ease-in')
      ]),
      transition('active => inactive', [
        animate('300ms ease-out')
      ])
    ])
  ]
})
export class BetterpayCheckoutComponent implements OnInit, OnDestroy {
  public drafts: any[];
  public paying: any[];
  public showInfo: any;
  public show: ShowNest;
  public actions: any;
  public betterpayForm: FormGroup;
  public cartTotals: any = { subtotal: 0, processingFee: 0, total: 0 };
  public email: string;
  public showID: string;
  public club_id: string;
  private showName: string;
  private uid: string;
  public metadata: any;
  public description: string;
  public isProcessing: boolean = false;

  // Collect.js related properties
  public isCardNumberValid: boolean = false;
  public isExpiryValid: boolean = false;
  public isCvvValid: boolean = false;
  public cardNumberTouched: boolean = false;
  public expiryTouched: boolean = false;
  public cvvTouched: boolean = false;
  public paymentToken: string = '';
  public cardBrand: string = '';
  public isValidating: boolean = false;

  // Saved cards properties
  public savedCards: any[] = [];
  public selectedSavedCard: any = null;
  public isLoadingSavedCards: boolean = false;
  public showNewCardForm: boolean = false;

  // Payment processing properties
  paymentError: string | null = null;
  useExistingCard = false;
  selectedCard: any = null;

  public isDevelopmentMode: boolean = false;
  public showManualPaymentModal: boolean = false;

  manualPaymentForm: FormGroup;

  entries: any[] = [];

  constructor(
    private _entriesService: EntriesService,
    private _personsService: PersonsService,
    private _authService: AuthService,
    private _betterpayService: BetterpayService,
    private _router: Router,
    private _firestore: Firestore,
    private _emailsService: EmailsService,
    private _showsService: ShowsService,
    private renderer: Renderer2,
    private ngZone: NgZone,
    @Inject(DOCUMENT) private document: Document,
    private fb: FormBuilder
  ) {
    this.cartTotals = {
      total: 0,
      entries: 0
    }
    this.showInfo = {};
    this.show = {
      id_concurso: 0,
      id_club: 0,
      fullname: ''
    };
    this.drafts = [];
    this.paying = [];
    this.actions = {
      canGoToPayment: false,
      formIsValid: false,
      betterpayFormVisible: false,
      moveObject: true
    }
    this.betterpayForm = new FormGroup({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      zipCode: new FormControl('', [Validators.required, Validators.pattern(/^(\d{5}(-\d{4})?|[A-Za-z]\d[A-Za-z]\s?\d[A-Za-z]\d)$/)])
    });
    this.email = localStorage.getItem('email') || (sessionStorage.getItem('email') || '');
    this.showID = sessionStorage.getItem('showID') || '';
    this.showName = sessionStorage.getItem('showname') || '';
    this.club_id = sessionStorage.getItem('club_id') || '';
    this.uid = localStorage.getItem('user_document_id') || (sessionStorage.getItem('user_document_id') || '');
    this.metadata = null;
    this.description = '';
    this.isDevelopmentMode = environment.production === false;
    this.manualPaymentForm = this.fb.group({
      paymentId: ['', Validators.required],
      transactionId: ['', Validators.required],
      last4: ['', [Validators.required, Validators.pattern('^[0-9]{4}$')]],
      paymentDate: ['', Validators.required]
    });
  }

  async ngOnInit() {
    const user = await this._authService.getLoggedUser();
    if (!this.email && user.email) {
      this.email = user.email;
      localStorage.setItem('email', this.email);
    }
    if (!this.uid) {
      this._personsService.getDocumentByEmail(this.email).then((doc: any) => {
        this.uid = doc.uid;
        localStorage.setItem('user_document_id', this.uid);
      });
    }

    // Form validation status changes
    this.betterpayForm.statusChanges.subscribe(status => {
      this.updateFormValidity();
    });

    await this.getShow(this.showID);
    await this.getConcurso();
    this.startCollectJs();
  }

  public async startCollectJs(): Promise<void> {
    // Load the Collect.js script, but don't initialize fields yet
    this.loadCollectJsScript();

    // Initialize tooltips
    setTimeout(() => {
      const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
      tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new (window as any).bootstrap.Tooltip(tooltipTriggerEl);
      });
    }, 500);
  }

  private loadCollectJsScript(): void {
    // Remove any existing Collect.js script to avoid duplicates
    const existingScript = this.document.querySelector('script[src*="Collect.js"]');
    if (existingScript) {
      existingScript.parentNode?.removeChild(existingScript);
    }
    // Create a new script element
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true; // Use async loading
    script.src = 'https://btrpay.transactiongateway.com/token/Collect.js';

    // Add the data-tokenization-key attribute that Collect.js requires
    const clubIdStr = sessionStorage.getItem('club_id');
    if (!clubIdStr) {
      console.error('No club ID found in session storage');
      return;
    }
    const clubId = Number(clubIdStr) as 562 | 565 | 567 | 1 | 377;
    if (!environment.betterpay[clubId]) {
      console.error('Invalid club ID:', clubId);
      return;
    }
    const token = environment.betterpay[clubId].key;

    console.log('Club string', this.show);
    console.log('Club Id', clubId);

    console.log('Using key:', environment.betterpay[clubId].key);

    script.setAttribute('data-tokenization-key', token);
    script.setAttribute('data-variant', 'inline');

    // Add event listeners for load and error events
    script.addEventListener('load', () => {
      // Give the script a moment to fully initialize
      setTimeout(() => {
        if (typeof window.CollectJS !== 'undefined') {
          // We'll configure CollectJS but NOT initialize the fields yet
          // Fields will only be initialized when the user clicks "Use a new card"
          this.configureCollectJs();
        } else {
          errorNotification('Payment System Error', 'The payment system failed to initialize. Please try again later.');
        }
      }, 500);
    });

    script.addEventListener('error', () => {
      console.error('Error loading Collect.js script');
      errorNotification('Payment System Error', 'The payment system failed to load. Please try again later.');
    });

    // Append the script to the document head
    document.head.appendChild(script);

    // Add a fallback to check if the script loaded correctly
    setTimeout(() => {
      if (typeof window.CollectJS === 'undefined') {
        console.error('CollectJS failed to load after timeout');

        // Try loading the script again with a different approach
        const fallbackScript = document.createElement('script');
        fallbackScript.type = 'text/javascript';
        fallbackScript.async = true;
        fallbackScript.src = 'https://btrpay.transactiongateway.com/token/Collect.js';
        fallbackScript.setAttribute('data-tokenization-key', token);
        fallbackScript.setAttribute('data-variant', 'inline');
        document.head.appendChild(fallbackScript);

        // Check again after another delay
        setTimeout(() => {
          if (typeof window.CollectJS !== 'undefined') {
            this.configureCollectJs();
          } else {
            console.error('CollectJS failed to load even with fallback');
            errorNotification('Payment System Error', 'The payment system could not be initialized. Please refresh the page and try again.');
          }
        }, 2000);
      }
    }, 5000);
  }

  // Configure CollectJS without initializing fields
  private configureCollectJs(): void {
    try {
      // Make sure CollectJS is available
      if (typeof window.CollectJS === 'undefined') {
        console.error('CollectJS is not defined');
        return;
      }
      // Store a reference to this for use in the callback
      const self = this;

      // Access CollectJS from the window object
      (window as any).CollectJS.configure({
        paymentType: 'cc',
        variant: 'inline',
        fields: {
          ccnumber: {
            selector: '#ccnumber',
            placeholder: '•••• •••• •••• ••••',
            title: 'Card Number'
          },
          ccexp: {
            selector: '#ccexp',
            placeholder: 'MM / YY',
            title: 'Expiration Date'
          },
          cvv: {
            selector: '#cccvv',
            placeholder: 'CVV',
            title: 'CVV'
          }
        },
        styleSniffer: true,
        customCss: {
          'background-color': 'transparent',
          'color': '#495057',
          'font-size': '1rem',
          'font-family': 'inherit',
          'border': 'none',
          'height': '100%',
          'width': '100%',
          'line-height': '1.5'
        },
        fieldsAvailableCallback: function () {
        },
        validationCallback: function (field: string, status: boolean, message: string) {

          if (field === 'ccnumber') {
            self.isCardNumberValid = status;
            self.cardNumberTouched = true;
            self.applyValidationStyles('#ccnumber', status);

            // Try to detect card brand from the message
            if (status && message) {
              self.detectCardBrand(message);
            } else {
              self.cardBrand = '';
            }
          } else if (field === 'ccexp') {
            self.isExpiryValid = status;
            self.expiryTouched = true;
            self.applyValidationStyles('#ccexp', status);
          } else if (field === 'cvv') {
            self.isCvvValid = status;
            self.cvvTouched = true;
            self.applyValidationStyles('#cccvv', status);
          }

          self.updateFormValidity();
        },
        callback: function (response: any) {

          if (response.error) {
            console.error('Error in tokenization:', response.error);
            self.paymentToken = '';
            errorNotification('Validation Error', 'Failed to validate card information. Please try again.');
            self.isValidating = false;
            self.isProcessing = false;
          } else {
            // Set the payment token
            self.paymentToken = response.token;
            console.log('paymentToken response', response);

            // Explicitly update the validation state
            self.isValidating = false;
            self.isProcessing = false;

            // Use NgZone to ensure Angular detects the changes
            self.ngZone.run(() => {
              // This will force Angular to detect the changes
              self.updateFormValidity();

              // Add a visual indicator that the token was received
              const paymentForm = document.querySelector('.card-body form');
              if (paymentForm) {
                const tokenIndicator = document.createElement('div');
                tokenIndicator.className = 'alert alert-success mt-3';
                tokenIndicator.innerHTML = '<i class="mdi mdi-check-circle me-2"></i>Card validated successfully. You can now complete your payment.';

                // Remove any existing indicator
                const existingIndicator = paymentForm.querySelector('.alert-success');
                if (existingIndicator) {
                  existingIndicator.remove();
                }

                paymentForm.appendChild(tokenIndicator);
              }

              // If we're in the middle of processing a payment, continue with it
              if (self.isProcessing) {
                setTimeout(() => {
                  self.processPaymentWithToken();
                }, 500);
              }
            });
          }
        },
        timeoutDuration: 20000, // Increase timeout to 20 seconds
        timeoutCallback: function () {
          // errorNotification('Validation Error', 'The card validation process timed out. Please try again.');
          console.log('timeoutCallback');
          self.isValidating = false;
          self.isProcessing = false;
        }
      });

      // Note: We're not starting the payment request here
      // It will only be started when the user clicks "Use a new card"
    } catch (error) {
      console.error('Error configuring CollectJS:', error);
      errorNotification('Payment System Error', 'There was an error setting up the payment form. Please try again later.');
    }
  }

  private detectCardBrand(message: string): void {
    const lowerMessage = message.toLowerCase();
    if (lowerMessage.includes('visa')) {
      this.cardBrand = 'visa';
    } else if (lowerMessage.includes('mastercard')) {
      this.cardBrand = 'mastercard';
    } else if (lowerMessage.includes('amex') || lowerMessage.includes('american express')) {
      this.cardBrand = 'amex';
    } else if (lowerMessage.includes('discover')) {
      this.cardBrand = 'discover';
    } else if (lowerMessage.includes('diners')) {
      this.cardBrand = 'diners';
    } else if (lowerMessage.includes('jcb')) {
      this.cardBrand = 'jcb';
    } else {
      this.cardBrand = '';
    }
  }

  private applyValidationStyles(selector: string, isValid: boolean): void {
    const element = document.querySelector(selector);
    if (element) {
      if (isValid) {
        element.classList.remove('is-invalid');
        element.classList.add('is-valid');
      } else {
        element.classList.remove('is-valid');
        element.classList.add('is-invalid');
      }
    }
  }

  private updateFormValidity(): void {
    const formValid = this.betterpayForm.valid;

    // If we have a selected saved card, we don't need to validate the card fields
    if (this.selectedSavedCard) {
      // For saved cards, we only need to check if the form is valid
      // We don't need to check card fields or payment token
      this.actions.formIsValid = formValid;
    } else {
      // For new cards, we need both form and card fields to be valid
      const cardFieldsValid = this.isCardNumberValid && this.isExpiryValid && this.isCvvValid;
      const personalInfoValid = this.betterpayForm.get('firstName')?.valid &&
        this.betterpayForm.get('lastName')?.valid &&
        this.betterpayForm.get('zipCode')?.valid;

      this.actions.formIsValid = formValid && cardFieldsValid && personalInfoValid && this.paymentToken;
    }
  }

  public async getConcurso() {
    try {
      this.showInfo = await this._entriesService.getEntryInfo(this.showID);
      await this.getShow(this.showID);
      this.getDrafts();
      this.cartTotals = {
        subtotal: 0,
        processingFee: 0,
        total: 0
      };
      this.actions = {
        moveObject: true,
        betterpayFormVisible: false,
        confirmationVisible: false,
        canGoToPayment: false,
        formIsValid: false
      };
      this.paying = [];

      // Calculate processing fee if applicable
      this.calculateProcessingFee();

    } catch (error) {
      console.error('Error getting show info:', error);
    }
  }

  public async getShow(idConcurso: string) {
    console.log('idConcurso', idConcurso);

    const response: any = await firstValueFrom(this._showsService.getShowNest(idConcurso));
    if (response.error) {
      errorNotification('Error', response.message);
      return;
    }
    this.show = response.data.concurso;
    console.log('show', this.show.id_club);
  }

  public async getDrafts() {
    const response = await this._personsService.getDocumentByEmail(this.email);

    const responseDrafts = await this._entriesService.getEntries(response.uid, this.showID, 'draft');
    if (responseDrafts.error) {
      errorNotification('Error', `Error getting drafts. ${responseDrafts.message}`);
      return;
    }
    this.drafts = responseDrafts;
  }

  public async deleteDraft(uid: string) {
    const { reason } = await this._entriesService.deleteDocument(this.uid, uid).then(() => ({ reason: null })).catch(reason => ({ reason }));
    if (reason) {
      errorNotification(reason.error.code, `Error deleting draft. ${reason.error.message}`);
      return;
    }
    this.getDrafts();
  }

  public moveObject(object: any, sourceArray: any[], destinationArray: any[]): void {
    // Find the index of the object in the source array
    const index = sourceArray.findIndex(item => item.uid === object.uid);

    // If the object is found in the source array
    if (index !== -1) {
      // Remove the object from the source array
      sourceArray.splice(index, 1);

      // Add the object to the destination array
      destinationArray.push(object);

      // Calculate subtotal from paying array
      this.cartTotals.subtotal = this.paying.reduce((acc, e) => acc + parseFloat(e.balance || (this.showInfo.entryAmount || '0')), 0);

      // Calculate processing fee
      this.calculateProcessingFee();

      // Update action flags
      this.actions.canGoToPayment = (this.paying.length > 0);
      this.setMetadata();
    }
  }

  // Calculate processing fee based on show.club.comision_stripe
  private calculateProcessingFee(): void {
    this.cartTotals.subtotal = this.paying.reduce((acc, e) => acc + parseFloat(e.balance || (this.showInfo.entryAmount || '0')), 0);

    // Check if we have a valid commission rate
    if (this.show?.club?.comision_stripe) {
      const commissionRate = parseFloat(this.show.club.comision_stripe) / 100;
      this.cartTotals.processingFee = this.cartTotals.subtotal * commissionRate;
    } else {
      this.cartTotals.processingFee = 0;
    }

    // Update total with processing fee
    this.cartTotals.total = this.cartTotals.subtotal + this.cartTotals.processingFee;
  }

  public selectPaymentMethod() {
    // Ensure processing fee is calculated before showing payment form
    this.calculateProcessingFee();

    // Apply animations with slight delay for better visual effect
    setTimeout(() => {
      this.actions.moveObject = false;

      // Small delay before showing the next section for smoother transition
      setTimeout(() => {
        this.actions.betterpayFormVisible = true;
        this.actions.confirmationVisible = false;

        // Get saved cards when the payment form is shown
        this.getSavedCards();
      }, 300);
    }, 100);

    // Note: We're not initializing Collect.js fields here anymore
    // Fields will only be initialized when the user clicks "Use a new card"
  }

  public returnToSelectEntries() {
    // Apply animations with slight delay for better visual effect
    setTimeout(() => {
      this.actions.betterpayFormVisible = false;
      this.actions.confirmationVisible = false;

      // Clean up Collect.js fields when returning to Entries step
      this.cleanupCollectJsFields();

      // Small delay before showing the next section for smoother transition
      setTimeout(() => {
        this.actions.moveObject = true;
      }, 300);
    }, 100);
  }

  public async processPayment() {

    if (!this.betterpayForm.valid || this.isProcessing) {
      errorNotification('Payment Error', 'Please complete all required fields before proceeding.');
      return;
    }

    // Check if we're using a saved card or a new card
    if (this.selectedSavedCard) {
      // Process with saved card
      this.isProcessing = true;
      await this.processPaymentWithSavedCard();
    } else {
      // Check if we have a token for the new card
      if (!this.paymentToken) {
        errorNotification('Payment Error', 'Please validate your card information first by clicking the "Validate Card" button.');
        return;
      }

      // Set processing state
      this.isProcessing = true;

      // Proceed with payment using new card
      await this.processPaymentWithToken();
    }
  }

  // New method to process payment with saved card
  private async processPaymentWithSavedCard() {
    this.isProcessing = true;
    LogRocket.track('Saved Card Payment Started', {
      email: this.email,
      showID: this.showID,
      showName: this.showName,
      totalEntries: this.paying.length,
      totalHorses: this.paying.map(e => e.horseName).join(', ')
    });

    try {

      // For direct API call to payment processor
      const paymentData: PaymentData = {
        first_name: this.betterpayForm.value.firstName,
        last_name: this.betterpayForm.value.lastName,
        zip: this.betterpayForm.value.zipCode,
        reference: 'sample reference',
        amount: this.cartTotals.total,
        type: 'saved_card', // Using saved_card type
        api_token: '7cc263a1-a490-4337-8302-151490495e56',
        entry: '000',
        id_concurso: this.showID,
        id_responsable: 123,
        customer_vault_id: this.selectedSavedCard.customer_vault_id,
        customer_vault_billing_id: this.selectedSavedCard.customer_vault_billing_id, // Required to identify which saved card to use
        online: true
        // Note: No customer_vault parameter when using a saved card
      };

      const processResponse = await firstValueFrom(this._betterpayService.transactPayment(paymentData));

      if (!processResponse || processResponse.error) {
        throw new Error(processResponse?.error?.message || 'Payment processing failed');
      }

      // Handle successful payment
      await this.handleSuccessfulPayment(processResponse.data as PaymentResponse);

    } catch (error: any) {
      console.error('Payment processing error:', error);
      errorNotification('Payment Error', error.message || 'An error occurred during payment processing');
      this.sendErrorEmail('Saved Card Payment Error', error.message || 'Unknown error', this.email);
    } finally {
      this.isProcessing = false;
    }
  }

  private async processPaymentWithToken() {
    this.isProcessing = true;
    LogRocket.track('New Card Payment Started', {
      email: this.email,
      showID: this.showID,
      showName: this.showName,
      totalEntries: this.paying.length,
      totalHorses: this.paying.map(e => e.horseName).join(', ')
    });
    try {

      // Determine the customer_vault value based on saved cards
      let customerVaultValue = 'add_customer';
      let typeValue = 'add_customer';

      if (this.savedCards.length > 0) {
        // If user already has saved cards and is adding a new one
        customerVaultValue = 'add_billing';
        typeValue = 'update_customer';
      }

      // When adding a card to an existing customer, we don't need to send customer_vault_billing_id
      if (customerVaultValue === 'add_customer') {
        // For new customers, we might need additional parameters here if required
      } else if (customerVaultValue === 'add_billing') {
      } else {
      }

      // Build the payment data object
      let paymentData: any = {
        first_name: this.betterpayForm.value.firstName,
        last_name: this.betterpayForm.value.lastName,
        address1: this.betterpayForm.value.address1,
        zip: this.betterpayForm.value.zipCode,
        reference: 'sample reference',
        amount: this.cartTotals.total,
        type: typeValue,
        api_token: '7cc263a1-a490-4337-8302-151490495e56',
        // entry: '000',
        id_concurso: this.showID,
        // id_responsable: null,
        payment_token: this.paymentToken,
        customer_vault: customerVaultValue,
        stored_credential_indicator: 'stored',
        customer_vault_id: this.email,
        online: true
      };

      // Initialize payment with Betterpay
      const initResponse = await firstValueFrom(this._betterpayService.transactPayment(paymentData));

      if (!initResponse || initResponse.error) {
        throw new Error(initResponse?.error?.message || 'Failed to initialize payment');
      }

      await this.handleSuccessfulPayment(initResponse.data as PaymentResponse);

    } catch (error: any) {
      console.error('Payment processing error:', error);
      errorNotification('Payment Error', error.message || 'An error occurred during payment processing');
      this.sendErrorEmail('Card Payment Error', error.message || 'Unknown error', this.email);
    } finally {
      this.isProcessing = false;
    }
  }

  private async handleSuccessfulPayment(paymentResponse: PaymentResponse) {
    const batch: WriteBatch = writeBatch(this._firestore);
    const entries: any[] = this.paying.map(e => {
      batch.set(e.ref, { status: 'Pending Back Number' }, { merge: true });
      const additionalRiders = e.riders.map((r: any) => ({
        riderUsef: r.USEF || (r.riderUSEF || ''),
        riderName: r.firstname || (r.riderFirstname || ''),
        riderMiddleName: '',
        riderLastName: r.lastname || (r.riderLastname || ''),
        riderFei: r.FEI || (r.riderFEI || ''),
        riderEc: r.EC || (r.riderEC || ''),
        //Address
        riderAddress: r.riderAddress || '',
        riderAddress2: r.riderAddress2 || '',
        riderCountry: r.riderCountry || '',
        riderCity: r.riderCity || '',
        riderState: r.riderState || '',
        riderZip: r.riderZip || '',
        //End Address
        riderEmail: '',
        riderPhone: r.riderPhonenumber || '',
        riderSSN: '',
        riderDOB: '',
        riderNationality: '',
        suspensionStatus: '',
        signatureRider: '',
      }));
      const [jinete] = additionalRiders;
      const entry_amount = parseFloat(e.balance || (this.showInfo.entryAmount || '0'));
      // Processing fee calculation could be added here if needed, we need to only include the part of the processing fee that is not already included in the entry amount.
      // In particular we need to account for the fact that a single payment can be used to pay for multiple entries, and the processing fee is the same for all entries.
      // The this.cartTotals.processingFee is the total processing fee for all entries, so we only need to account for the part of the processing fee that applies to each entry.

      let entry_charge = 0;

      try {
        const commissionRate = this.show?.club?.comision_stripe || 0;
        entry_charge = entry_amount * (1 + commissionRate / 100);
      } catch (error) {
        console.warn('Error calculating entry charge:', error);
        // Default to the entry amount if calculation fails
        entry_charge = entry_amount;
      }

      return {
        idConcurso: this.showID,
        nombreConcurso: this.showName,
        email: this.email,
        caballo: {
          horseUsef: e.horseUSEF || '',
          horseEC: e.horseEC || '',
          horseName: e.horseName || '',
          horseFei: e.horseFEI || '',
          horseBreed: '',
          horseSex: '',
          horseHgt: '',
          horseColor: '',
          horseYrFoaled: '',
          horseSire: '',
          horseDam: '',
          horseBreeder: '',
          horseOwner: ''
        },
        jinete,
        additionalRiders,
        idEntrenador: null,
        entrenador: {
          trainerName: e.trainername || '',
          trainerUsef: e.trainerUSEF || '',
          trainerEC: e.trainerEC || '',
          trainerFEI: e.trainerFEI || '',
          trainerLastname: e.trainerLastname || '',
          trainerEmail: '',
          trainerPhone: e.trainerPhonenumber || '',
          //Address
          trainerAddress: e.trainerAddress || '',
          trainerAddress2: e.trainerAddress2 || '',
          trainerCountry: e.trainerCountry || '',
          trainerCity: e.trainerCity || '',
          trainerState: e.trainerState || '',
          trainerZip: e.trainerZip || '',
          signatureTrainer: ''
          //End Address
        },
        responsable: {
          payeeUsef: e.payeeUSEF || '',
          payeeEC: e.payeeEC || '',
          payeeFEI: e.payeeFEI || '',
          payeeName: e.payeename || '',
          payeeMiddleName: '',
          payeeLastName: e.payeeLastname || '',
          payeeSSN: '',
          payeePhone: e.payeePhonenumber || '',
          //Address
          payeeAddress: e.payeeAddress || '',
          payeeAddress2: e.payeeAddress2 || '',
          payeeCountry: e.payeeCountry || '',
          payeeCity: e.payeeCity || '',
          payeeState: e.payeeState || '',
          payeeZip: e.payeeZip || '',
          //End Address
          payeeIncorporated: ''
        },
        person_responsible: {
          id: e.person_responsible || '',
          name: e.responsibleName || '',
          lastname: e.responsibleLastname || '',
          ecNumber: e.responsibleEC || '',
          signature: e.responsibleSignature || '',
          phone: e.responsiblePhone || '',
          email: e.responsibleEmail || '',
          address: {
            street: e.responsibleAddress || '',
            street2: e.responsibleAddress2 || '',
            country: e.responsibleCountry || '',
            city: e.responsibleCity || '',
            state: e.responsibleState || '',
            zip: e.responsibleZip || ''
          },
        },
        propietario: {
          ownerFEI: e.ownerFEI,
          ownerEC: e.ownerEC || '',
          ownerName: e.ownername,
          ownerLastName: e.ownerLastname,
          ownerUsef: e.ownerUSEF,
          ownerPhone: e.ownerPhonenumber,
          ownerMiddleName: '',
          //Address
          ownerAddress: e.ownerAddress || '',
          ownerAddress2: e.ownerAddress2 || '',
          ownerCountry: e.ownerCountry || '',
          ownerCity: e.ownerCity || '',
          ownerState: e.ownerState || '',
          ownerZip: e.ownerZip || '',
          signatureOwner: ''
          //End Address
        },
        pruebas: e.pruebas || [],
        cargos: e.conceptos || [],
        pago: {
          paymentId: paymentResponse.paymentId || '',
          metodo: 'creditcard',
          pagado: paymentResponse.apiResponse.response_code === '100',
          notas: paymentResponse.apiResponse.transactionid || '',
          transactionId: paymentResponse.apiResponse.transactionid || null,
          entry_amount,
          entry_charge,
          processing_fee: this.cartTotals.processingFee,
          processing_fee_rate: this.show?.club?.comision_stripe || 0,
          total_with_fee: this.cartTotals.total,
          last4: paymentResponse.last4 || (paymentResponse.apiResponse.cc_number ? paymentResponse.apiResponse.cc_number.slice(-4) : ''),
          gateway: 'betterpay'
        },
        estatus: paymentResponse.apiResponse.response_code === '100' ? '1' : '0',
        otros: {
          stableWith: e.stablingWith || '',
          arrivalDate: ''
        },
        taxId: e.taxID || '',
        fingerprint: {},
        retryTrace: paymentResponse.apiResponse.transactionid || '',
        emergencyContact: {
          name: e.emergencyContact || '',
          phone: e.emergencyPhone || ''
        }
      };
    });

    // Update to show confirmation step instead of just hiding the form
    setTimeout(() => {
      this.actions.betterpayFormVisible = false;

      // Small delay before showing the confirmation section for smoother transition
      setTimeout(() => {
        this.actions.moveObject = false;
        this.actions.confirmationVisible = true;
      }, 300);
    }, 100);

    try {
      const { error } = await firstValueFrom(this._entriesService.crearSolicitudes(entries));
      if (error) {
        this.sendErrorEmail('Error creating online entry with credit card',
          `Email: ${this.email}, ${Object.entries<any>(this.metadata).map(([key, value]) => `${key}: ${value}`).join(', ')}, Error: ${error}`,
          this.email, true);
        throw new Error(error);
      }

      // Commit the batch and redirect to the home page
      await batch.commit();
      // this.setMailchimpMember();
      this.getDrafts();
      this._router.navigate(['/']);
      LogRocket.track('Online Entry Created', {
        email: this.email,
        showID: this.showID,
        showName: this.showName,
        totalEntries: this.paying.length,
        totalHorses: this.paying.map(e => e.horseName).join(', ')
      });
      successNotification('Successful payment', 'The payment has been processed successfully.');
    } catch (error: any) {
      errorNotification('Error', `Error generating online entry. Please try again later. ${error.message || error}`);
    }
  }

  private setMetadata() {
    let metadata: any = {
      systemVersion: environment.version,
      paymentMethod: 'creditcard'
    };

    if (this.paying.length === 0) {
      this.metadata = metadata;
      return;
    }

    metadata['user'] = this.uid;
    this.paying.forEach((entry: any, i: number) => {
      metadata[`entry${i + 1}`] = entry.uid || '';
    });

    this.metadata = metadata;
    this.description = `Show: ${this.showName}, Total online entries: ${this.paying.length}, horses: ${this.paying.map(e => e.horseName).join(', ')}`;
  }

  async setMailchimpMember() {
    const data = {
      email_address: this.email,
      id_concurso: this.showID
    };

    try {
      const response = await firstValueFrom(this._emailsService.setMailchimpMember(this.email, this.showID));
      if (response.status == 400) {
        console.error('Error setting mailchimp member: ', response.detail);
      } else {
      }
    } catch (error) {
      console.error('Error: ', error);
    }
  }

  public sendErrorEmail(title: string, error: any, customer: string, php: boolean = false) {
    this._emailsService.sendEmail(title + ' for ' + customer, error);
    if (php) this._emailsService.sendEmailPhp(title + ' for ' + customer, error);
  }

  public getCardBrandIcon(): string {
    switch (this.cardBrand) {
      case 'visa': return 'fa-brands fa-cc-visa';
      case 'mastercard': return 'fa-brands fa-cc-mastercard';
      case 'amex': return 'fa-brands fa-cc-amex';
      case 'discover': return 'fa-brands fa-cc-discover';
      case 'diners': return 'fa-brands fa-cc-diners-club';
      case 'jcb': return 'fa-brands fa-cc-jcb';
      default: return 'fa-regular fa-credit-card';
    }
  }

  // Add a method to check if fields are initialized and reinitialize if needed
  private ensureFieldsInitialized(): void {
    // Check if the fields are properly initialized
    const ccnumberElement = document.querySelector('#ccnumber iframe');
    const ccexpElement = document.querySelector('#ccexp iframe');
    const cccvvElement = document.querySelector('#cccvv iframe');

    if (!ccnumberElement || !ccexpElement || !cccvvElement) {

      // Clear the containers first
      const ccnumberContainer = document.querySelector('#ccnumber');
      const ccexpContainer = document.querySelector('#ccexp');
      const cccvvContainer = document.querySelector('#cccvv');

      if (ccnumberContainer) ccnumberContainer.innerHTML = '';
      if (ccexpContainer) ccexpContainer.innerHTML = '';
      if (cccvvContainer) cccvvContainer.innerHTML = '';

      // Try a different approach to initialize the fields
      if (typeof window.CollectJS !== 'undefined') {
        // First reconfigure CollectJS
        this.configureCollectJs();

        // Then start the payment request after a short delay
        setTimeout(() => {
          try {
            window.CollectJS.startPaymentRequest();

            // Check one more time after another delay
            setTimeout(() => {
              const finalCheck = document.querySelector('#ccnumber iframe');
              if (!finalCheck) {
                console.error('Final attempt to initialize fields failed');
                // Only show error notification if we're showing the new card form
                if (this.showNewCardForm) {
                  errorNotification('Payment Form Error', 'Could not initialize the payment form. Please refresh the page and try again.');
                }
              } else {
              }
            }, 1000);
          } catch (error) {
            console.error('Error during field reinitialization:', error);
            if (this.showNewCardForm) {
              errorNotification('Payment Form Error', 'Could not initialize the payment form. Please refresh the page and try again.');
            }
          }
        }, 300);
      } else {
        // If CollectJS is not available, reload the script
        console.error('CollectJS not available for reinitialization');
        this.loadCollectJsScript();
      }
    } else {
    }
  }

  public validateCard(): void {
    // Check if card fields are valid
    if (!this.isCardNumberValid || !this.isExpiryValid || !this.isCvvValid || this.isValidating || this.paymentToken) {
      return;
    }

    // Check if form fields are valid
    if (!this.betterpayForm.get('firstName')?.valid ||
      !this.betterpayForm.get('lastName')?.valid ||
      !this.betterpayForm.get('zipCode')?.valid) {
      errorNotification('Validation Error', 'Please fill in all required fields correctly before validating your card.');
      return;
    }

    this.isValidating = true;

    if (typeof window.CollectJS !== 'undefined') {
      try {
        // Get form values
        const firstName = this.betterpayForm.get('firstName')?.value;
        const lastName = this.betterpayForm.get('lastName')?.value;
        const zipCode = this.betterpayForm.get('zipCode')?.value;

        // Use startPaymentRequest to trigger tokenization with cardholder info
        window.CollectJS.startPaymentRequest({
          name: `${firstName} ${lastName}`,
          firstName: firstName,
          lastName: lastName,
          zip: zipCode
        });

        // The callback will handle setting the token and updating the UI
      } catch (error) {
        console.error('Error triggering tokenization:', error);
        errorNotification('Validation Error', 'Failed to validate card information. Please try again.');
        this.isValidating = false;
      }
    } else {
      console.error('CollectJS not available for tokenization');
      errorNotification('Validation Error', 'Card validation system is not available. Please refresh the page and try again.');
      this.isValidating = false;
    }
  }

  // Add method to get saved cards
  private async getSavedCards(): Promise<void> {
    if (!this.email || !this.club_id) {
      console.error('Email or club_id not available for fetching saved cards');
      return;
    }

    this.isLoadingSavedCards = true;

    try {
      const response = await firstValueFrom(this._betterpayService.getSavedCards(this.email, this.club_id));

      if (response && response.data && Array.isArray(response.data)) {
        this.savedCards = response.data;
        // If we have saved cards, don't show the new card form initially
        if (this.savedCards.length === 0) {
          this.showNewCardForm = true;
          this.showAddNewCard();
        }
      } else {
        console.error('Invalid response format for saved cards:', response);
        this.savedCards = [];
        this.showNewCardForm = true;
      }
    } catch (error) {
      console.error('Error fetching saved cards:', error);
      this.savedCards = [];
      this.showNewCardForm = true;
      errorNotification('Error', 'Failed to retrieve saved payment methods. You can add a new card instead.');
    } finally {
      this.isLoadingSavedCards = false;
    }
  }

  // Method to select a saved card
  public selectSavedCard(card: any): void {
    this.selectedSavedCard = card;
    this.showNewCardForm = false;

    // Reset the Collect.js fields validation since we're not using them
    this.paymentToken = '';
    this.isCardNumberValid = false;
    this.isExpiryValid = false;
    this.isCvvValid = false;

    // Clean up any initialized Collect.js fields
    this.cleanupCollectJsFields();

    // When a card is selected but not yet confirmed with "Use this card",
    // we should disable the Complete Payment button
    this.actions.formIsValid = false;

    // Reset the "Use this card" button visibility when switching cards
    const useThisCardButton = document.querySelector('.saved-cards-list .btn-primary');
    if (useThisCardButton) {
      useThisCardButton.classList.remove('fading-out', 'd-none');
    }

    // Remove any existing success indicators
    const existingIndicator = document.querySelector('.card-body form .alert-success');
    if (existingIndicator) {
      existingIndicator.remove();
    }

    // Force Angular change detection
    setTimeout(() => {
      // Ensure the Complete Payment button is disabled
      const completePaymentButton = document.getElementById('complete-payment-button');
      if (completePaymentButton && !completePaymentButton.hasAttribute('disabled')) {
        completePaymentButton.setAttribute('disabled', 'disabled');
      }
    }, 0);
  }

  // Method to clean up Collect.js fields without removing the script
  private cleanupCollectJsFields(): void {
    // Clear the containers
    const ccnumberContainer = document.querySelector('#ccnumber');
    const ccexpContainer = document.querySelector('#ccexp');
    const cccvvContainer = document.querySelector('#cccvv');

    if (ccnumberContainer) ccnumberContainer.innerHTML = '';
    if (ccexpContainer) ccexpContainer.innerHTML = '';
    if (cccvvContainer) cccvvContainer.innerHTML = '';

    // Reset payment token and validation states
    this.paymentToken = '';
    this.isCardNumberValid = false;
    this.isExpiryValid = false;
    this.isCvvValid = false;
    this.cardNumberTouched = false;
    this.expiryTouched = false;
    this.cvvTouched = false;
    this.cardBrand = '';

  }

  // Method to show the new card form
  public async showAddNewCard(): Promise<void> {
    this.selectedSavedCard = null;
    this.showNewCardForm = true;

    // Reset form validity since we now need to validate the new card
    this.updateFormValidity();

    // Reset any existing payment token
    this.paymentToken = '';

    // Now initialize the Collect.js fields since the user wants to use a new card    

    // Clear the containers first
    const ccnumberContainer = document.querySelector('#ccnumber');
    const ccexpContainer = document.querySelector('#ccexp');
    const cccvvContainer = document.querySelector('#cccvv');

    if (ccnumberContainer) ccnumberContainer.innerHTML = '';
    if (ccexpContainer) ccexpContainer.innerHTML = '';
    if (cccvvContainer) cccvvContainer.innerHTML = '';

    // Initialize the fields with a short delay to ensure the DOM is ready
    setTimeout(() => {
      if (typeof window.CollectJS !== 'undefined') {
        // Start the payment request to initialize the fields       // window.CollectJS.startPaymentRequest();

        // Check if fields are properly initialized after a delay
        setTimeout(() => {
          this.ensureFieldsInitialized();
        }, 1000);
      } else {
        console.error('CollectJS not available when trying to show new card form');
        // Try to load the script if it's not available
        this.loadCollectJsScript();

        // Try again after a delay
        setTimeout(() => {
          if (typeof window.CollectJS !== 'undefined') {
            // window.CollectJS.startPaymentRequest();
          }
        }, 2000);
      }
    }, 300);
  }


  // Method to use a saved card for payment
  public useThisCard(card: any): void {
    this.selectSavedCard(card);

    // Make sure required form fields are filled with default values if empty
    if (!this.betterpayForm.get('firstName')?.value) {
      this.betterpayForm.get('firstName')?.setValue(card.first_name || 'Card');
    }

    if (!this.betterpayForm.get('lastName')?.value) {
      this.betterpayForm.get('lastName')?.setValue(card.last_name || 'Owner');
    }

    if (!this.betterpayForm.get('zipCode')?.value) {
      this.betterpayForm.get('zipCode')?.setValue(card.cc_zip || '12345');
    }

    // Force form validation
    this.betterpayForm.updateValueAndValidity();

    // Explicitly enable the Complete Payment button by setting formIsValid to true
    this.actions.formIsValid = true;

    // Add a visual indicator that the card is ready to use
    const paymentForm = document.querySelector('.card-body form');
    if (paymentForm) {
      // Remove any existing success indicators
      const existingIndicator = paymentForm.querySelector('.alert-success');
      if (existingIndicator) {
        existingIndicator.remove();
      }

      // Add new indicator
      const tokenIndicator = document.createElement('div');
      tokenIndicator.className = 'alert alert-success mt-3';

      // Get cardholder name from available fields
      const cardholderName = card.cardholder || (card.first_name ? `${card.first_name} ${card.last_name}`.trim() : '');

      // Create success message with card type and cardholder name if available
      let successMessage = `<i class="mdi mdi-check-circle me-2"></i>Card selected: ${card.cc_number}`;

      if (card.cc_type) {
        successMessage += ` (${card.cc_type})`;
      }

      successMessage += ` - Exp: ${card.cc_exp}`;

      if (cardholderName) {
        successMessage += ` - Cardholder: ${cardholderName}`;
      }

      successMessage += `. You can now complete your payment.`;

      tokenIndicator.innerHTML = successMessage;
      paymentForm.appendChild(tokenIndicator);
    }

    // Fade out the "Use this card" button
    const useThisCardButton = document.querySelector('.saved-cards-list .btn-primary');
    if (useThisCardButton) {
      // Add fade-out animation
      useThisCardButton.classList.add('fading-out');

      // After animation completes, hide the button
      setTimeout(() => {
        useThisCardButton.classList.add('d-none');
      }, 500); // Match this to the CSS animation duration
    }

    // Force Angular change detection and check again
    setTimeout(() => {
      // Force enable the Complete Payment button for saved cards
      const completePaymentButton = document.getElementById('complete-payment-button');
      if (completePaymentButton) {
        completePaymentButton.removeAttribute('disabled');
      }

      // Make sure the formIsValid flag is set to true
      this.ngZone.run(() => {
        this.actions.formIsValid = true;
      });
    }, 100);
  }

  submitPayment() {
    this.isProcessing = true;
    this.paymentError = null;

    // Use the total with processing fee for payment
    const amount = this.cartTotals.total;

    // Log payment details for debugging
    console.log('Payment submission:', {
      amount,
      subtotal: this.cartTotals.subtotal,
      processingFee: this.cartTotals.processingFee,
      processingFeeRate: this.show?.club?.comision_stripe || 0
    });

    if (this.useExistingCard && this.selectedCard) {
      // ... existing code ...
    }
  }

  ngOnDestroy() {
    // Clean up any subscriptions or resources
  }

  /**
   * Opens a modal dialog for manual payment entry
   * This is for administrative use only to save entries that were already paid
   * but not properly registered in the system
   */
  openManualPaymentModal() {
    if (!this.isDevelopmentMode) {
      console.warn('Manual payment entry is only available in development mode');
      return;
    }
    
    if (this.paying.length === 0) {
      errorNotification('Error', 'No entries selected for payment');
      return;
    }
    
    this.showManualPaymentModal = true;
  }
  
  /**
   * Closes the manual payment modal
   */
  closeManualPaymentModal() {
    this.showManualPaymentModal = false;
  }
  
  /**
   * Processes a manual payment entry
   * Creates a PaymentResponse object with the provided information
   * and calls handleSuccessfulPayment to save the entry
   */
  processManualPayment(paymentInfo: any) {
    console.log('Processing manual payment with info:', paymentInfo);
    
    // Create a minimal PaymentResponse object
    const paymentResponse: PaymentResponse = {
      paymentId: paymentInfo.paymentId,
      transactionId: paymentInfo.transactionId,
      last4: paymentInfo.last4,
      status: 'successful',
      message: 'Manual payment entry processed successfully',
      apiResponse: {
        response: 'Approved',
        responsetext: 'Manual entry',
        authcode: '12345',
        transactionid: paymentInfo.transactionId,
        avsresponse: '',
        cvvresponse: '',
        orderid: '',
        type: 'sale',
        response_code: '100', // Always 100 for approved
        first_name: '',
        last_name: '',
        cc_number: `****${paymentInfo.last4}`,
        customer_vault_id: '',
        cardholder_auth: '',
        cc_type: '',
        cc_exp: '',
        customer_vault: '',
        entry_mode: '',
        initial_transaction_id: '',
        initiated_by: '',
        network_token_used: '',
        billing_method: ''
      },
      data: {
        first_name: '',
        last_name: '',
        zip: '',
        reference: '',
        amount: this.cartTotals.total,
        type: 'sale',
        api_token: '',
        entry: '',
        id_concurso: this.showID,
        id_responsable: 0,
        customer_vault_id: '',
        customer_vault_billing_id: '',
        online: true
      },
      transaction: null,
      transactionCommission: {
        entry: '',
        id_concurso: this.showID,
        referencia: '',
        monto_pagar: this.cartTotals.total,
        procesado: true,
        created: new Date().toISOString(),
        tipo: 'sale',
        id_concepto: '',
        quantity: '',
        fecha_pago: paymentInfo.paymentDate ? new Date(paymentInfo.paymentDate).toISOString() : new Date().toISOString(),
        id_responsable: null,
        id_entrenador: null,
        id_caballo: null,
        nombre: null,
        pagar: null,
        cantidad: null,
        deduccion: null,
        iva: null,
        id_binomio: null,
        anulado: null,
        id: 0
      }
    };
    
    // Close the modal
    this.closeManualPaymentModal();
    
    // Call the existing method with our constructed object
    this.handleSuccessfulPayment(paymentResponse);
  }

  async submitManualPayment() {
    if (this.manualPaymentForm.valid) {
      try {
        const formData = this.manualPaymentForm.value;
        const paymentData = {
          paymentId: formData.paymentId,
          transactionId: formData.transactionId,
          last4: formData.last4,
          paymentDate: formData.paymentDate,
          amount: this.cartTotals.total,
          status: 'completed'
        };

        // Process the manual payment using the paying array (selected entries)
        this.processManualPayment(paymentData);
        
        // Close modal and show success message
        this.showManualPaymentModal = false;
        successNotification('Payment recorded successfully', 'Success');
        
        // Navigate to my-entries page
        this._router.navigate(['/my-entries']);
      } catch (error) {
        console.error('Error recording manual payment:', error);
        errorNotification('Error recording payment. Please try again.', 'Error');
      }
    }
  }
}
