import { Component, OnInit, HostListener } from '@angular/core';
import { AuthService } from './services/auth.service';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { IntercomAuthService } from './services/intercom-auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'ED-Online-entries';

  constructor(
    private authService: AuthService,
    private router: Router,
    private intercomAuthService: IntercomAuthService
  ) {}

  ngOnInit(): void {
    // First identify the user for error logging systems
    this.authService.identifyUserLOG(
      localStorage.getItem('user_document_id') || '', 
      localStorage.getItem('email') || '', 
      localStorage.getItem('username') || ''
    );

    // Then update Intercom with user information
    this.intercomAuthService.updateIntercom();

    // Close sidebar on route change for mobile
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.closeSidebar();
    });
  }

  // Close sidebar when clicking outside on mobile
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    
    // If sidebar is open and click is outside sidebar and not on toggle button
    if (
      document.documentElement.classList.contains('sidebar-enable') && 
      !target.closest('.leftside-menu') && 
      !target.closest('.button-toggle-menu')
    ) {
      this.closeSidebar();
    }
  }

  // Helper method to close sidebar
  private closeSidebar(): void {
    document.documentElement.classList.remove('sidebar-enable');
  }
}
