import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { IntercomService } from './intercom.service';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IntercomAuthService {
  constructor(
    private authService: AuthService,
    private intercomService: IntercomService,
    private router: Router
  ) {
    // Listen for route changes to update Intercom
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.updateIntercom();
    });
  }

  /**
   * Update Intercom with the current user's information
   */
  public updateIntercom(): void {
    this.intercomService.updateIntercom();
  }

  /**
   * Handle login success
   */
  public onLoginSuccess(): void {
    this.updateIntercom();
  }

  /**
   * Handle logout
   */
  public onLogout(): void {
    this.intercomService.shutdownIntercom();
  }

  /**
   * Handle signup success
   */
  public onSignupSuccess(): void {
    this.updateIntercom();
  }
} 