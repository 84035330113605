import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { FormsModule, ReactiveFormsModule  }   from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SignaturePadModule } from 'angular-signature-pad-v2';
import { Router } from '@angular/router';

// import * as firebase from 'firebase/app';

import { AppRoutingModule } from './app-routing.module';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { DatePipe } from '@angular/common';
import { CreateEntryComponent } from './create-entry/create-entry.component';
import { SelectShowComponent } from './select-show/select-show.component';
import { LeftMenuComponent } from './left-menu/left-menu.component';
import { TopMenuComponent } from './top-menu/top-menu.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { MyEntriesComponent } from './my-entries/my-entries.component';
import { OnlineCheckoutComponent } from './online-checkout/online-checkout.component';
import { EntryInvoiceComponent } from './entry-invoice/entry-invoice.component';
import { AddDropsComponent } from './add-drops/add-drops.component';
import { TrainerReservationComponent } from './trainer-reservation/trainer-reservation.component';
import { ScheduleComponent } from './schedule/schedule.component';
import { HistoryComponent } from './history/history.component';
import { EntryComponent } from './entry/entry.component';
import { AddClassesComponent } from './add-classes/add-classes.component';
import { AutocompleteAddresssComponent } from './autocomplete-addresss/autocomplete-addresss.component';
import { AddEntryComponent } from './add-entry/add-entry.component';
import { CheckoutReiningComponent } from './checkout-reining/checkout-reining.component';
import { EditEntryReinignComponent } from './edit-entry-reinign/edit-entry-reinign.component';
import { EditEntryComponent } from './edit-entry/edit-entry.component';
import { MyCatalogComponent } from './my-catalog/my-catalog.component';
import { StallsComponent } from './stalls/stalls.component';
import { StripePaymentFormComponent } from './forms/stripe-payment-form/stripe-payment-form.component';
import { FeedOrdersComponent } from './feed-orders/feed-orders.component';
import * as Sentry from "@sentry/angular";
import { BetterpayCheckoutComponent } from './betterpay-checkout/betterpay-checkout.component';
import { SharedModule } from './shared/shared.module';
import { IntercomService } from './services/intercom.service';
import { IntercomAuthService } from './services/intercom-auth.service';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SignUpComponent,
    CreateEntryComponent,
    SelectShowComponent,
    LeftMenuComponent,
    TopMenuComponent,
    CheckoutComponent,
    MyEntriesComponent,
    OnlineCheckoutComponent,
    EntryInvoiceComponent,
    AddDropsComponent,
    TrainerReservationComponent,
    ScheduleComponent,
    HistoryComponent,
    EntryComponent,
    AddClassesComponent,
    AutocompleteAddresssComponent,
    AddEntryComponent,
    CheckoutReiningComponent,
    EditEntryReinignComponent,
    EditEntryComponent,
    MyCatalogComponent,
    StallsComponent,
    StripePaymentFormComponent,
    FeedOrdersComponent,
    BetterpayCheckoutComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SignaturePadModule,
    BrowserAnimationsModule,
    SharedModule,
    provideFirebaseApp( () => initializeApp({
      apiKey: "AIzaSyBFhMU1VwH3l1Hf4yGoQE6Qm3SEY9fIO0Y",
      authDomain: "ecuestredigital.firebaseapp.com",
      databaseURL: "https://ecuestredigital.firebaseio.com",
      projectId: "ecuestredigital",
      storageBucket: "ecuestredigital",
      messagingSenderId: "581815221685"
    })),
    provideFirestore( () => getFirestore()),
    provideAuth(() => getAuth()),
    Sentry.TraceModule
  ],
  providers: [
    DatePipe,
    IntercomService,
    IntercomAuthService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
       showDialog: false,
      }),
    }, {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
