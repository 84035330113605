import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

declare let Intercom: any;

@Injectable({
  providedIn: 'root'
})
export class IntercomService {
  private initialized = false;

  constructor(
    private router: Router
  ) {
    // Initialize Intercom when the service is created
    this.initializeIntercom();
  }

  /**
   * Initialize Intercom with the app ID
   */
  private initializeIntercom(): void {
    if (this.initialized) return;
    
    // Initialize Intercom with the app ID
    Intercom('boot', {
      app_id: 'k6da0k6b'
    });
    
    this.initialized = true;
    
    // Listen for route changes to update Intercom
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.updateIntercom();
    });
  }

  /**
   * Update Intercom with the current user's information
   */
  public updateIntercom(): void {
    const userId = localStorage.getItem('user_document_id');
    const email = localStorage.getItem('email');
    const name = localStorage.getItem('username');
    
    // Only update Intercom if we have a user ID (logged in user)
    if (userId) {
      Intercom('boot', {
        app_id: 'k6da0k6b',
        user_id: userId,
        name: name || '',
        email: email || '',
        created_at: Math.floor(Date.now() / 1000) // Current timestamp in seconds
      });
    } else {
      // If no user is logged in, just boot Intercom without user data
      Intercom('boot', {
        app_id: 'k6da0k6b'
      });
    }
  }

  /**
   * Shutdown Intercom when user logs out
   */
  public shutdownIntercom(): void {
    Intercom('shutdown');
  }
} 